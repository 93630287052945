import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { Pagination } from '../Components/Pagination/Pagination'
import { TextHeader } from '../Components/Header/TextHeader'
import { CarouselSlider } from '../Components/Carousel/Carousel'
import { MotionLayout } from '../Components/MotionLayout/MotionLayout'
import { HomeData } from '../Assets/Data/Data'

export const Home = () => {
  return (
    <Container>
      <MotionLayout />
      <Row className="Home">
        <Col sm={6}>
          <TextHeader title={HomeData.Title} />
          <Pagination pagination={HomeData.underTitle} />
        </Col>
        <Col sm={6}>
          <CarouselSlider />
        </Col>
      </Row>
    </Container>
  )
}
