import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { ImageTextOverlay } from '../ImageTextoverlay/ImageTextOverlay'

import Prefab from '../../Assets/Images/Prefab.png'
import Hus from '../../Assets/Images/Hus.png'
import Inredning from '../../Assets/Images/Inredning.png'
import Betong from '../../Assets/Images/Betong.png'

export const CarouselSlider = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 2,
    },
  }
  return (
    <Carousel
      responsive={responsive}
      renderButtonGroupOutside={true}
      focusOnSelect={true}
    >
      <ImageTextOverlay image={Hus} title={'Hus'} alt={'Hus'} link={'Hus'} />
      <ImageTextOverlay
        image={Prefab}
        title={'Prefab'}
        alt={'Prefab'}
        link={'Prefab'}
      />
      <ImageTextOverlay
        image={Inredning}
        title={'Inredning'}
        alt={'Inredning'}
        link={'Inredning'}
      />

      <ImageTextOverlay
        image={Betong}
        title={'Betong'}
        alt={'betong'}
        link={'Betong'}
      />
    </Carousel>
  )
}
