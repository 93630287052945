import React from 'react'
import { Row } from 'react-bootstrap'

import { Pagination } from './Pagination'
import { TextHeader } from '../Header/TextHeader'

export const PaginationSingle = (props) => {
  return (
    <Row className="pagination-center">
      <TextHeader />
      <Pagination pagination={props.title} />
    </Row>
  )
}
