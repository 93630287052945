import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  Row,
  DropdownButton,
  Nav,
  Container,
  Dropdown,
} from 'react-bootstrap'
import MediaQuery from 'react-responsive'

export const Header = () => {
  const maxWidth = window.innerWidth

  console.log(maxWidth)
  return (
    <Container fluid>
      <Row className="Header">
        <Col className="nav">
          <Nav variant="pills">
            <MediaQuery minWidth={900}>
              <Link to="/">
                <Button variant="outline-primary">Hem</Button>
              </Link>
              <Link to="About">
                <Button variant="outline-primary">Om oss </Button>
              </Link>
              <Link to="Avtalspartner">
                <Button variant="outline-primary">Avtalspartner </Button>
              </Link>
              <Link to="/">
                <Button variant="outline-primary">
                  Beställning och offert
                </Button>
              </Link>
              <Link to="/jobb">
                <Button variant="outline-primary"> För tillfället </Button>
              </Link>
              <DropdownButton
                id={`dropdown-variants-Primary`}
                title={'Produkter'}
                className="custom-dropdown"
              >
                <Dropdown.Item as={Link} eventKey={'Betong'} to="Betong">
                  Betong
                </Dropdown.Item>
                <Dropdown.Item as={Link} eventKey={'Prefab'} to="Prefab">
                  Marknadens
                </Dropdown.Item>
                <Dropdown.Item as={Link} eventKey={'Hus'} to="Hus">
                  Hus
                </Dropdown.Item>
                <Dropdown.Item as={Link} eventKey={'Inredning'} to="Inredning">
                  Inredning
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item as={Link} eventKey={'Produkter'} to="Produkter">
                  Alla Produkter
                </Dropdown.Item>
              </DropdownButton>
            </MediaQuery>

            {/* Mobil Navigation */}
            <MediaQuery maxWidth={900}>
              <DropdownButton
                id={`dropdown-variants-Primary`}
                title={'Meny'}
                className="custom-dropdown"
              >
                <Dropdown.Item as={Link} eventKey={'Hem'} to="/">
                  Hem
                </Dropdown.Item>
                <Dropdown.Item as={Link} eventKey={'About'} to="about">
                  Om oss
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  eventKey={'Avtalspartner'}
                  to="Avtalspartner"
                >
                  Avtalspartner
                </Dropdown.Item>
                <Dropdown.Item as={Link} eventKey={'Bestallning'} to="/">
                  Beställning och offert
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item as={Link} eventKey={'Jobb'} to="Jobb">
                  För tillfället
                </Dropdown.Item>
              </DropdownButton>
              <DropdownButton
                id={`dropdown-variants-Primary`}
                title={'Produkter'}
                className="custom-dropdown"
              >
                <Dropdown.Item as={Link} eventKey={'Betong'} to="Betong">
                  Betong
                </Dropdown.Item>
                <Dropdown.Item as={Link} eventKey={'Prefab'} to="Prefab">
                  Prefab
                </Dropdown.Item>
                <Dropdown.Item as={Link} eventKey={'Hus'} to="Hus">
                  Hus
                </Dropdown.Item>
                <Dropdown.Item as={Link} eventKey={'Inredning'} to="Inredning">
                  Inredning
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item as={Link} eventKey={'Produkter'} to="Produkter">
                  Alla Produkter
                </Dropdown.Item>
              </DropdownButton>
            </MediaQuery>
          </Nav>
        </Col>
      </Row>
    </Container>
  )
}
