import React from 'react'
import { motion } from 'framer-motion'

export const Pagination = (props) => {
  return (
    <div className="under__title">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: 100 }}
        transition={{ duration: 1, delay: 1 }}
        className="borderWidth"
      />
      <p>{props.pagination}</p>
    </div>
  )
}
