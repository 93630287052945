import React from 'react'
import { LottieAnimation } from '../Lottie/Lottie'
import loading from '../../Assets/Data/Lottie/loading.json'

export const Loading = () => {
  return (
    <div className="loading">
      <LottieAnimation animation={loading} />
    </div>
  )
}
