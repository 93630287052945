import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Container } from 'react-bootstrap'
import { MotionLayout } from '../MotionLayout/MotionLayout'
import { Alert, Button } from '@mui/material'
import { Link } from 'react-router-dom'

const createData = (name, jobbkategoti, Område, datum) => {
  return { name, jobbkategoti, Område, datum }
}

const rows = [
  createData('noJobb'),
  // createData('Customer Sales', 'Säljare', 'Helsingborg', '20/9 - 2022'),
  // createData('Customer Sales', 'Säljare', 'Helsingborg', '20/9 - 2022'),
  // createData('Customer Sales', 'Säljare', 'Helsingborg', '20/9 - 2022'),
]

console.log(rows[0].name)

export const Jobb = () => {
  return (
    <>
      <MotionLayout />
      <Container className="jobb">
        <div className="jobb-header">
          <h4>Vill du jobba hos oss?</h4>
          <p>
            Vill du bli en del av familjen, ha koll på tillängligheten av jobb
            erbjudande som passar just dig
          </p>
          <Link to="/Avtalspartner">
            <Button variant="contained">Kontakta oss</Button>
          </Link>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="right">Jobbkategori</TableCell>
                <TableCell align="right">Område</TableCell>
                <TableCell align="right">Ansök senast</TableCell>
              </TableRow>
            </TableHead>
            {rows[0].name === 'noJobb' ? null : (
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.jobbkategoti}</TableCell>
                    <TableCell align="right">{row.Område}</TableCell>
                    <TableCell align="right">{row.datum}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {rows[0].name === 'noJobb' && (
          <div className="nojobs">
            <Alert severity="error">Inga jobb tillängliga förtillfället</Alert>
          </div>
        )}
      </Container>
    </>
  )
}
