import React from 'react'

export const TextImage = (props) => {
  return (
    <div className="textImage">
      <div className="overlay">
        <p>{props.text1}</p>
        <p>{props.text2}</p>
        <p>{props.text3}</p>
        <p>{props.text4}</p>
        <p>{props.text5}</p>
      </div>
    </div>
  )
}
