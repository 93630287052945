import React, { useEffect } from 'react'
import { motion } from 'framer-motion'

export const MotionLayout = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <motion.div
      initial={{ height: '100%' }}
      animate={{ height: 0 }}
      transition={{ duration: 1 }}
      className="motionTransition"
    />
  )
}
