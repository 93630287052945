import React from 'react'
import { SingleProdukt } from '../../Components/SingleProdukt/SingleProdukt'

import Image from '../../Assets/Images/Hus.png'
import { HusData } from '../../Assets/Data/Data'

export const Hus = () => {
  return (
    <>
      <SingleProdukt
        pagination={'Hus'}
        image={Image}
        title={'Hus'}
        info={HusData.info}
      />
    </>
  )
}
