import React from 'react'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'

export const ImageTextOverlay = (props) => {
  return (
    <div className="image-grid">
      <NavLink to={{ pathname: `/${props.link}` }}>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={props.image}
          alt={props.alt}
        />
        <div className="overlay">
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            {props.title}
          </motion.p>
        </div>
      </NavLink>
    </div>
  )
}
