import { Button, Box, Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <Box className="footer">
      <Container maxWidth="lg">
        <Grid container>
          <Grid xs={12} sm={4} className="footer-nav">
            <Link to="/">
              <Button variant="outline-primary">Hem</Button>
            </Link>
            <Link to="About">
              <Button variant="outline-primary">Om oss </Button>
            </Link>
            <Link to="Avtalspartner">
              <Button variant="outline-primary">Avtalspartner </Button>
            </Link>
            <Link to="/">
              <Button variant="outline-primary">Beställning och offert</Button>
            </Link>
            <Link to="/jobb">
              <Button variant="outline-primary">Jobb / Kontakt </Button>
            </Link>
          </Grid>
          <Grid xs={12} sm={4}>
            <p>
              Behöver ni kontakta oss eller <br />
              fråga allmänna fårgor?
            </p>
            <Link to="/jobb">
              <Button color="primary" variant="contained">
                Kontakta
              </Button>
            </Link>
          </Grid>

          <Grid xs={12} sm={4}>
            <p>
              Vi erbjuder en del olika tjänster <br />
              kolla igenom våra produkter för mer information!
            </p>
            <Link to="/Produkter">
              <Button color="primary" variant="contained">
                Våra Produkter
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
