import React, { useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { TextField, Button, MenuItem, Alert } from '@mui/material'
import emailjs from '@emailjs/browser'
import { ThemeProvider, createTheme } from '@mui/material/styles'

const validationSchema = yup.object({
  email: yup
    .string('Skriv din emailadress')
    .email('Skriv en giltig email')
    .required('Email is required'),
  name: yup.string('Skriv ditt namn').required('Namn är obligatoriskt'),
  message: yup
    .string('Skriv ditt meddelande')
    .required('Skriv ditt meddelande'),
  phone: yup
    .string('Skriv ditt telefonnummer')
    .required('Telefon nummer är obligatoriskt'),
})

export const Email = () => {
  const form = useRef()
  const [currency, setCurrency] = useState()
  const [showElement, hideElement] = useState(true)

  const theme = createTheme({
    palette: {
      primary: {
        main: '#fefefe',
      },
      secondary: {
        main: '#fefefe',
      },
    },
  })

  const currencies = [
    {
      value: 'Företag',
      label: 'Företag',
    },
    {
      value: 'Privatperson',
      label: 'Privatperson',
    },
  ]

  const resetform = () => {
    hideElement(true)
  }

  const handleChange = (event) => {
    setCurrency(event.target.value)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      message: '',
      phone: '',
    },
    validationSchema: validationSchema,

    onSubmit: () => {
      emailjs
        .sendForm(
          'service_7ve5wfn',
          'template_d94p5ne',
          form.current,
          '-iSlkjEGCFtZbBghn'
        )
        .then(
          (result) => {
            console.log(result.text)
          },
          (error) => {
            console.log(error.text)
          }
        )
      hideElement(false)
    },
  })

  return (
    <>
      {showElement === true && (
        <>
          <div className="info" id="about">
            <h4>KONTAKTA OSS</h4>
            <p>Vid Offertförfrågan eller anmäla frågor</p>
          </div>

          <div className="formgroup">
            <form ref={form} onSubmit={formik.handleSubmit} className="form">
              <ThemeProvider theme={theme}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  color="primary"
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{ input: { borderColor: 'red' } }}
                />

                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Namn"
                  name="name"
                  color="primary"
                  variant="outlined"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Telefon"
                  name="phone"
                  variant="outlined"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Select"
                  name="type"
                  value={currency}
                  onChange={handleChange}
                  helperText="Privat / Företag"
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  id="outlined-name"
                  label="Meddelande"
                  multiline
                  rows={6}
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                />
                <Button color="secondary" variant="contained" type="submit">
                  Skicka
                </Button>
              </ThemeProvider>
            </form>
          </div>
        </>
      )}

      {!showElement && (
        <>
          <div className="alertNotification">
            <Alert severity="success">Meddelande skickat!</Alert>
            <p>
              Tack för ditt meddelande, vi återkommer till dig så snart som
              möjligt!
            </p>
            <Button variant="contained" onClick={() => resetform()}>
              Skicka om ditt meddelande
            </Button>
          </div>
        </>
      )}
    </>
  )
}
