import React from 'react'
import Logo from '../../Assets/Images/Logo.png'

export const TextHeader = (props) => {
  return (
    <>
      <img src={Logo} alt="Samarbetspartnern" width="530" className="logo" />
      <h1 className="title-text">{props.title}</h1>
    </>
  )
}
