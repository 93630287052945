import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { Pagination } from '../Components/Pagination/Pagination'
import { TextImage } from '../Components/TextImage'
import { Omoss } from '../Assets/Data/Data'
import { LottieAnimation } from '../Components/Lottie/Lottie'
import construction from '../Assets/Data/Lottie/constuction.json'
import { MotionLayout } from '../Components/MotionLayout/MotionLayout'

export const About = () => {
  return (
    <Container>
      <MotionLayout />
      <Row className="Home">
        <Col sm={2}>
          <Pagination pagination={Omoss.text} />
        </Col>
        <Col sm={8}>
          <div className="lottie">
            <LottieAnimation animation={construction} />
          </div>
          <TextImage
            text1={Omoss.Aboutone}
            text2={Omoss.Abouttwo}
            text3={Omoss.AboutThree}
            text4={Omoss.AboutFour}
            text5={Omoss.AboutFive}
          />
        </Col>
      </Row>
    </Container>
  )
}
