import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { PaginationSingle } from '../Components/Pagination/PaginationSingle'
import { ImageTextOverlay } from '../Components/ImageTextoverlay/ImageTextOverlay'

import Betong from '../Assets/Images/Betong.png'
import Prefab from '../Assets/Images/Hus.png'
import Hus from '../Assets/Images/Prefab.png'
import Inredning from '../Assets/Images/Inredning.png'

export const Produkter = () => {
  return (
    <div>
      <Container className="justify-content-center produkter">
        <PaginationSingle title={'Produkter'} />
        <Row md={4}>
          <Col>
            <ImageTextOverlay
              image={Betong}
              title={'Betong'}
              alt={'betong'}
              link={'Betong'}
            />
          </Col>
          <Col>
            <ImageTextOverlay
              image={Prefab}
              title={'Marknadens'}
              alt={'Prefab'}
              link={'Prefab'}
            />
          </Col>
          <Col>
            <ImageTextOverlay
              image={Hus}
              title={'Hus'}
              alt={'Hus'}
              link={'Hus'}
            />
          </Col>
          <Col>
            <ImageTextOverlay
              image={Inredning}
              title={'Inredning'}
              alt={'Inredning'}
              link={'Inredning'}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
