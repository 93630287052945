import './App.scss'
import React, { useState, useEffect } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Routes, Route } from 'react-router-dom'

import { Header } from './Components/Header/Header'
import { Loading } from './Components/Loading/Loading'
import { Jobb } from './Components/Jobb/Jobb'
import { Home } from './Pages/Home'
import { About } from './Pages/About'
import { Produkter } from './Pages/Produkter'
import { Avtalspartner } from './Pages/Avtalspartner'
import { Prefab } from './Pages/Produkter/Prefab'
import { Hus } from './Pages/Produkter/Hus'
import { Betong } from './Pages/Produkter/Betong'
import { Inredning } from './Pages/Produkter/Inredning'
import { Footer } from './Components/Footer/Footer'

function App() {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2500)
  })

  const theme = createTheme({
    palette: {
      primary: {
        main: '#ffc61c',
      },
      secondary: {
        main: '#fefefe',
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      {isLoading === true ? (
        <Loading />
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="About" element={<About />} />
            <Route path="Produkter" element={<Produkter />} />
            <Route path="Avtalspartner" element={<Avtalspartner />} />
            <Route path="Prefab" element={<Prefab />} />
            <Route path="Betong" element={<Betong />} />
            <Route path="Hus" element={<Hus />} />
            <Route path="Inredning" element={<Inredning />} />
            <Route path="Jobb" element={<Jobb />} />
          </Routes>
          <Footer />
        </>
      )}
    </ThemeProvider>
  )
}

export default App
