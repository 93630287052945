import React from 'react'
import Lottie from 'react-lottie'

export const LottieAnimation = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    controls: false,
    animationData: props.animation,
  }

  return (
    <div className="lottie-icon">
      <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
    </div>
  )
}
