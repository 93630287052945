import React from 'react'
import { SingleProdukt } from '../../Components/SingleProdukt/SingleProdukt'
import { InredningGrid } from '../../Components/Inredning/InredningGrid'

import Image from '../../Assets/Images/Inredning.png'
import { InredningData } from '../../Assets/Data/Data'

export const Inredning = () => {
  return (
    <>
      <SingleProdukt
        pagination={'Inredning'}
        image={Image}
        title={'Inredning'}
        info={InredningData.info}
      />
      <a href="#inredningsprodukterna" className="inredningsprodukter">
        Se våra inrednings <strong>produkter</strong>
        <span> &#8595;</span>
      </a>
      <div id="inredningsprodukterna">
        <InredningGrid />
      </div>
    </>
  )
}
