import React from 'react'
import { SingleProdukt } from '../../Components/SingleProdukt/SingleProdukt'

import Image from '../../Assets/Images/Betong.png'
import { BetongData } from '../../Assets/Data/Data'

export const Betong = () => {
  return (
    <SingleProdukt
      pagination={'Betong'}
      image={Image}
      title={'Betong'}
      info={BetongData.info}
    />
  )
}
