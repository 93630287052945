import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'

import Badrum from '../../Assets/Images/inredning/Badrum.png'
import Dörrar from '../../Assets/Images/inredning/Dörrar.png'
import Fönster from '../../Assets/Images/inredning/Fönster.png'
import Garderob from '../../Assets/Images/inredning/Garderob.png'
import Kök from '../../Assets/Images/inredning/Kök.png'
import Mått from '../../Assets/Images/inredning/Måttanpassadeluckor.png'
import Tillbehör from '../../Assets/Images/inredning/Tillbehör.png'
import Trappor from '../../Assets/Images/inredning/Trappor.png'
import Tvättstuga from '../../Assets/Images/inredning/Tvättstuga.png'
import Vitvaror from '../../Assets/Images/inredning/Vitvaror.png'

const itemData = [
  {
    img: Badrum,
    title: 'Badrum',
  },
  {
    img: Dörrar,
    title: 'Dörrar',
  },
  {
    img: Fönster,
    title: 'Fönster',
  },
  {
    img: Garderob,
    title: 'Garderob',
  },
  {
    img: Kök,
    title: 'Kök',
  },
  {
    img: Mått,
    title: 'Måttanpassade luckor',
  },
  {
    img: Tillbehör,
    title: 'Tillbehör',
  },
  {
    img: Trappor,
    title: 'Trappor',
  },
  {
    img: Tvättstuga,
    title: 'Tvättstuga',
  },
  {
    img: Vitvaror,
    title: 'Vitvaror',
  },
]

export const InredningGrid = () => {
  return (
    <Container>
      <Row className="inredning-grid">
        {itemData.map((item) => (
          <Col xs={5} md={2} align="center" key={item.img} className="col-grid">
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
            <div className="col-grid-background">
              <p>{item.title}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}
