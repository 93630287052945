export const HomeData = {
  Title: 'Konsultverksamhet & Produkter',
  underTitle: 'Bläddra bland våra tjänster',
}

export const Omoss = {
  Title: 'Konsultverksamhet & Produkter',
  text: 'Vilka är vi',
  Aboutone:
    'Familjeföretaget Samarbetspartnern levererar klassiskt moderna produkter för inspirerande inredningar. ',
  Abouttwo:
    'Utifrån en klassisk och tidlös grund utvecklas varumärket kontinuerligt för att möta marknadens intresse för design, färg och form. Samarbetspartnern vill vara en inspirerande förebild på inredningar.',
  AboutThree:
    'Det breda urvalet av sortimentet ger stora möjligheter till dina kunder. Kvalitet är inget entydligt begrepp, men för våran del handlar det om att förena färger, form och funktion på ett optimalt sätt. ',
  AboutFour:
    'Samarbetspartnern ska genom kompetens och närhet vara din bästa samarbetspartner inom området Nyproduktion och Inredningar. ',
  AboutFive:
    'Till kompetensen lägger vi starkt personligt engagemang. Vår gedigna tekniska kunnande och många års praktiska erfarenheter gör att vi  snabbare erbjuder lösningar som vi vet fungerar i verkligheten vid industriellt byggande. På så sätt blir även du en tryggare samarbetspartner för dina kunder.',
}

export const BetongData = {
  info: 'All vår betongtillverkning sker i samförstånd med klientel och våra leverantörer i deras egna fabriker. Tillverkningen övervakas nogrannt av specialister som kan allt om materialet och byggprocessen. Vi har lång tids erfarenhet av byggnation med betong och produktionen av materialet. Formandet, strukturen och måtten tas fram i samförstånd med experter och klientel utifrån önskemål och existerande ritningar. ',
}
export const PrefabData = {
  info: 'Prefabricering av byggnadernas komponenter sätts ihop i våra samarbetspartners fabriker. utifrån geografiskt läge. Processen av husmodulernas konstruktion och valmöjligheterna därav är moderniseras utefter marknades senaste standarder. Stomelement utgör numera ett dominerande inslag i bostads-, industri-, kontors- och affärshusbyggandet.',
}
export const InredningData = {
  info: [
    'Utöver byggnation så erbjuder Samarbetspartnern en helhetlösning. Heltäckande inredning till klientens nya hem eller kontor kan beställas i samförstånd med inredningsdesigners eller helt självständigt. Vi leverar, ställer upp, städar och monterar utefter behov och önskemål. Kataloger skickas vid offertförfrågan eller vid önskemål.',
  ],
}
export const HusData = {
  info: 'Vår partners planerar och förverkligar klientens husdrömmar i nära kommunikation och samförstånd. Nödvändig rådgivning från agenter finns tillhands i fråga om bygglov, viktiga dokument och information för planering av husbyggande samt övriga tillstånd. Klienten får en personlig arkitekt som leder projektet tillsammans med klienten. Arkitekten har nära dialog med klienten i alla projektets delar.',
}

export const Kontakta = {
  titel:
    'Kontakta oss gärna om du har några frågor! Vi försöker alltid svara så snabbt vi kan. ',
  info: 'Vill ni ingå i ett samarbete med oss?',
  merInfo: 'Kontakta oss för mer information.',
}
