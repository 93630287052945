import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ImageTextOverlay } from '../ImageTextoverlay/ImageTextOverlay'
import { PaginationSingle } from '../Pagination/PaginationSingle'

export const SingleProdukt = (props) => {
  return (
    <Container className="justify-content-center SingleProdukt">
      <PaginationSingle title={props.pagination} />
      <Row className="singleprodukt-row">
        <Col sm={4}>
          <ImageTextOverlay
            image={props.image}
            title={props.title}
            alt={props.title}
            link={props.title}
          />
        </Col>
        <Col sm={5}>
          <p>{props.info}</p>
        </Col>
      </Row>
    </Container>
  )
}
