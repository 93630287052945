import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { Pagination } from '../Components/Pagination/Pagination'
import { TextHeader } from '../Components/Header/TextHeader'
import { motion } from 'framer-motion'
import { HomeData } from '../Assets/Data/Data'
import { Email } from '../Components/Email/Email'
import { MotionLayout } from '../Components/MotionLayout/MotionLayout'

export const Avtalspartner = () => {
  return (
    <Container fluid>
      <MotionLayout />
      <Row className="Home kontakta">
        <Col>
          <div className="header-title">
            <TextHeader title={HomeData.Title} />
            <Pagination pagination={'Avtalspartner'} />
          </div>
        </Col>
        <Col className="col-form">
          <motion.div
            initial={{ x: '1000px', opacity: 0 }}
            animate={{ x: '0', opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="primary-background"
          >
            <Email />
          </motion.div>
        </Col>
      </Row>
    </Container>
  )
}
